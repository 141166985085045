<template>
   <div class="contenter" style="height:100%;padding-top:88px;padding-bottom:64px;">
    <Header style="margin-top:-88px;position: relative;"></Header>
        <div style="min-height:100%;width:100%;backgroup:#fdfdfd;">
            <div class="detailsWraps" v-show="showLoading==false">
                <h2 class="tith2" v-show="tab==1" :title="info.Title">{{info.Title}}</h2>
                <div class="detaContent" v-show="tab==1">
                    <div class="deta1 cleft">
                        <ul>
                            <li class="" v-show="info.Author">
                                <div class="titname cleft">作者：</div>
                                <div class="contentname cleft">{{info.Author}}</div>
                            </li>
                            <li class="" v-show="info.Organ">
                                <div class="titname cleft">单位：</div>
                                <div class="contentname cleft">{{info.Organ}}</div>
                            </li>
                            <li class="" v-show="info.PubTime">
                                <div class="titname cleft">发表时间：</div>
                                <div class="contentname cleft">{{info.PubTime}}</div>
                            </li>
                            <li class="" v-show="info.Keyword">
                                <div class="titname cleft">关键词：</div>
                                <div class="contentname cleft">{{info.Keyword}}</div>
                            </li>
                            <li class="" v-show="info.Summary">
                                <div class="titname cleft">摘要：</div>
                                <div class="contentname cleft">{{info.Summary}}</div>
                            </li>
                            <li class="clear"></li>
                        </ul>
                    </div>
                    <div class="deta2 cright">
                        <div class="imgbox">
                            <div class="imgs"><img :src="info.Imgurl" alt=""></div>
                            <div class="tits" v-show="info.Source" :title="'《'+info.Source+'》'">《{{info.Source}}》</div>
                            <div class="p" v-show="info.Rinfactor">复合影响因子：{{info.Rinfactor}}</div>
                            <div class="p" v-show="info.Cinfactor">综合影响因子：{{info.Cinfactor}}</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <h2 class="tith2" v-show="tab==2" :title="info.Title">{{tirmStr(info.Title)}}</h2>
                <div class="detaContent" v-show="tab==2">
                    <!-- 没有封面图片 -->
                    <div class="data0" v-show="!info.Imgurl">
                        <ul>
                            <li class="" v-show="info.Author">
                                <div class="titname cleft">Authors：</div>
                                <div class="contentname cleft">{{tirmStr(info.Author)}}</div>
                            </li>
                            <li class="" v-show="info.Organ">
                                <div class="titname cleft">Affiliation：</div>
                                <div class="contentname cleft">{{tirmStr(info.Organ)}}</div>
                            </li>
                            <!-- <li class="" v-show="info.PubTime">
                                <div class="titname cleft">Time of publication：</div>
                                <div class="contentname cleft">{{info.PubTime}}</div>
                            </li> -->
                            <li class="" v-show="info.Keyword">
                                <div class="titname cleft">Keywords：</div>
                                <div class="contentname cleft">{{tirmStr(info.Keyword)}}</div>
                            </li>
                            <li class="" v-show="info.Summary">
                                <div class="titname cleft">Abstract：</div>
                                <div class="contentname cleft">{{tirmStr(info.Summary)}}</div>
                            </li>
                            <li class="clear"></li>
                        </ul>
                    </div>
                    <!-- 有封面图片 -->
                    <div class="deta1 cleft" v-show="info.Imgurl">
                        <ul>
                            <li class="" v-show="info.Author">
                                <div class="titname cleft">Authors：</div>
                                <div class="contentname cleft">{{tirmStr(info.Author)}}</div>
                            </li>
                            <li class="" v-show="info.Organ">
                                <div class="titname cleft">Affiliation：</div>
                                <div class="contentname cleft">{{tirmStr(info.Organ)}}</div>
                            </li>
                            <!-- <li class="" v-show="info.PubTime">
                                <div class="titname cleft">Time of publication：</div>
                                <div class="contentname cleft">{{info.PubTime}}</div>
                            </li> -->
                            <li class="" v-show="info.Keyword">
                                <div class="titname cleft">Keywords：</div>
                                <div class="contentname cleft">{{tirmStr(info.Keyword)}}</div>
                            </li>
                            <li class="" v-show="info.Summary">
                                <div class="titname cleft">Abstract：</div>
                                <div class="contentname cleft">{{tirmStr(info.Summary)}}</div>
                            </li>
                            <li class="clear"></li>
                        </ul>
                    </div>
                    <div class="deta2 cright" v-show="info.Imgurl">
                        <div class="imgbox">
                            <div class="imgs"><img :src="info.Imgurl" alt=""></div>
                            <div class="tits" :title="'《'+info.Source+'》'">《{{info.Source}}》</div>
                            <div class="p" v-show="info.Rinfactor">复合影响因子：{{tirmStr(info.Rinfactor)}}</div>
                            <div class="p" v-show="info.Cinfactor">综合影响因子：{{tirmStr(info.Cinfactor)}}</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="btnPage">
                    <div class="itempag">【上一条】：<span class="ispan" v-if="info.title_pre" @click="getPreTitle(info.pre_id)" :title="info.title_pre">{{info.title_pre}}</span><span v-else>无</span></div>
                    <div class="itempag">【下一条】：<span class="ispan" v-if="info.title_next" @click="getNextTitle(info.next_id)" :title="info.title_next">{{info.title_next}}</span><span v-else>无</span></div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <div class="loading" v-show="showLoading==true">            
            <div class="loadingInner"></div>
        </div>
    </div>
</template>
<script>

import Header from './commons/Header.vue';
import Footer from './commons/Footer.vue';
import axios from 'axios';
import {
  config,
  urlEpsBak,
} from "../until/common.js";
export default {
    name:'Paperdetails',
    components:{
        Header,
        Footer
    },
    data(){
        return{
            olap_url:null,
            showLoading:false,
            info:{},
            tab:null,
        }
    },
    methods:{
        // tirmStr(){

        // },
        tirmStr:function(str){
            if(this.tab==2){//外文期刊字段值可能存在前后空格
                if(str){
                    return str.replace(/^\s+|\s+$/g,"");//去掉字段值的前后空格
                }else{
                    return str;
                }
            }
            
        },
        getPreTitle(id){
            this.getinfo(id)
        },
        getNextTitle(id){
            this.getinfo(id)
        },
        getinfo(id){
            var that = this;
            that.showLoading = true;
            axios.get(config.baseUrl+'api/comgedata?sp=EPSZH_JOURNAL_REFDETAIL2&p=ARTID&v='+id).then((res)=> {
                if(res&&res.data){
                    window.console.log(res.data.result[0]);
                    that.info = res.data.result[0];
          
                    if(res.data.result[0].PubTime){
                        var str1 = res.data.result[0].PubTime.substr(0,10);
                        var strs = str1.replace(/\s/g, "");
                        var arr = strs.split('/');
                        arr.forEach(function(v,i){
                            if(v.length==1){
                                arr[i] = '0'+v;
                            }
                        });
                        res.data.result[0].PubTime = arr.join('-'); 
                    }
                    
                    // var jsonData = res.data;
                    // var jsonStr = jsonData.substr(1, jsonData.length - 2);
                    // var dataList = JSON.parse(jsonStr);
                    // that.info = dataList.Table[0];
                    // var str1 = dataList.Table[0].PubTime.substr(0,10);
                    // var strs = str1.replace(/\s/g, "");
                    // var arr = strs.split('/');
                    // arr.forEach(function(v,i){
                    //     if(v.length==1){
                    //         arr[i] = '0'+v;
                    //     }
                    // }) 
                    // dataList.Table[0].PubTime = arr.join('-'); 
                    that.showLoading = false;
                } 
            });
        }
    },
    mounted() {
        var that = this;
        that.olap_url = urlEpsBak;//(http://olap.epsnet.com.cn/)
        that.getinfo(that.$route.query.id);
        that.tab = that.$route.query.tab;
    },
}
</script>
<style lang="scss">
    @import '../scss/variables.scss';
    .detailsWraps{
        height:auto;
        width:1200px;
        margin:10px auto;
        padding:0 10px 10px;
        box-sizing:border-box;
        background:$colorWhite;
        .tith2{
            padding:20px 5px 10px 0px;
            box-sizing:border-box;
            line-height:26px;
            font-size:$font18;
            word-break:break-all;
            // height:60px;
            // line-height:60px;
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
        }
        .detaContent{
            width:100%;
            min-height:400px;
            
            .data0{
                width:100%;
                height:100%;
                ul{
                    width:100%;
                    height:100%;
                    li{
                        width:100%;
                        padding-top:10px;
                        box-sizing:border-box;
                        .titname{
                            line-height:32px;
                            font-weight:bold;
                            width:100px;
                            font-size:$font16;
                            margin-bottom:10px;
                        }
                        .contentname{
                            line-height:32px;
                            width:1050px;
                            font-size:$font16;
                            margin-bottom:10px;
                            word-break:break-all;
                            
                        }
                    }
                }
            }


            .deta1{
                height:100%;
                width:880px;
                ul{
                    li{
                        padding-top:10px;
                        box-sizing:border-box;
                        .titname{
                            line-height:32px;
                            font-weight:bold;
                            width:100px;
                            font-size:$font16;
                            margin-bottom:10px;
                        }
                        .contentname{
                            line-height:32px;
                            width:735px;
                            font-size:$font16;
                            margin-bottom:10px;
                            white-space:pre-wrap;
                            word-break:break-all;
                        }
                    }
                }
            }
            .deta2{
                height:100%;
                width:300px;
                .imgbox{
                    background:$colorff;
                    width:283px;
                    height:355px;
                    .imgs{
                        width:142px;
                        height:240px;
                        margin:0 auto;
                        padding-top: 20px;
                        box-sizing:border-box;
                        img{
                            width:100%;
                            height:100%;
                        }
                    }
                    .tits{
                        height:40px;
                        line-height:40px;
                        text-align:center;
                        font-size:$font16;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .p{
                        height:30px;
                        line-height:30px;
                        text-align:center;
                        font-size:$font16;
                    }
                }
            }
        }
        .btnPage{
            height:80px;
            width:100%;
            padding:10px 0;
            box-sizing:border-box;
            border-top: 1px dashed $colorGreyBorder;
            .itempag{
                cursor: pointer;
                height:30px;
                line-height:30px;
                font-size:$font16;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                span{
                    font-size:$font16;
                }
                .ispan{
                    font-size:$font16;
                    &:hover{
                        color:$colorBlue;
                    }
                }
                
            }
        }
    }
</style>